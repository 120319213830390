import { Dispatch, SetStateAction, ReactNode, createContext, useState, useContext } from 'react';

import { AES, enc } from 'crypto-js';

import IPaymentInfo from 'src/shared/interfaces/general/payment';

/**Estrutura esperada das props do contexto
 * @param paymentInfo objeto contendo as informações da compra do usuário
 * @param setPaymentTypes setter do paymentInfo
 */
interface IPaymentData {
   paymentInfo: IPaymentInfo;
   setPaymentInfo: Dispatch<SetStateAction<IPaymentInfo>>;
}

/**Estrutura esperada do provider do contexto */
interface IPaymentProviderProps {
   children: ReactNode;
}

export const PaymentContext = createContext({} as IPaymentData);

export function PaymentProvider({ children }: IPaymentProviderProps) {
   /**Variável que mantém as informações acerca do pagamento */
   const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>(() => {
      const params = new URL(window.location.href).searchParams;

      if (params.get('igti_checkout_payment_info')) {
         const obj = JSON.parse(
            AES.decrypt(
               window.location.href.split('igti_checkout_payment_info=')[1],
               process.env.REACT_APP_CHECKOUT_URL_SECRET_KEY!
            ).toString(enc.Utf8)
         );

         const { payment_type, transaction_id, installments, transaction_value } = obj;

         return {
            ...(transaction_id
               ? { transaction_id, value: transaction_value }
               : { transaction_id: null, value: 0 }),
            installments: installments ?? 1,
            payment_type,
            installment_value: 0,
            discounts: [],
            additionals: [],
         };
      } else
         return {
            value: 0,
            installments: 1,
            installment_value: 0,
            payment_type: '-',
            discounts: [],
            additionals: [],
         } as IPaymentInfo;
   });

   return (
      <PaymentContext.Provider
         value={{
            paymentInfo,
            setPaymentInfo,
         }}
      >
         {children}
      </PaymentContext.Provider>
   );
}

/**Hook utilizado para acessar as informações do contexto */
export function usePayment(): IPaymentData {
   const context = useContext(PaymentContext);

   if (!context) {
      throw new Error('usePayment must be used within a PaymentProvider');
   }

   return context;
}
