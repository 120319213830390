import { FC, useState, useEffect } from "react";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomLoader from "src/shared/components/general/Loader";

import { useProduct } from "src/shared/contexts/Product";
import { usePayment } from "src/shared/contexts/Payment";

import {
  StyledCard,
  StyledItemsList,
  StyledValuesList,
  PaymentTypeContainer,
} from "./style";

const Abstract: FC = () => {
  /**Variáveis que mantém o(s) objeto(s) do(s) produto(s) sendo adquirido(s) pelo usuário */
  const { productTypes, products, acquiredProducts, setAcquiredProducts } =
    useProduct();
  /**Variável que mantém as informações de pagamento do usuário */
  const { paymentInfo, setPaymentInfo } = usePayment();

  /**Variável que mantém o valor total da compra sem descontos */
  const [totalValue, setTotalValue] = useState(0);
  /**Variável que mantém o valor total da(s) taxa(s) de matrícula (não participa(m) dos descontos) */
  const [enrollmentValue, setEnrollmentValue] = useState(0);
  /**Variável que mantém o valor total do desconto a ser aplicado */
  const [totalDiscountValue, setTotalDiscountValue] = useState(0);
  /**Variável que mantém o valor do nome do produto que vem da URL */
  const [urlProductName, setUrlProductName] = useState("");
  /**Flag que controla o status de carregamento das infos que vem da URL */
  const [isLoading, setIsLoading] = useState(false);

  /**Atualiza o nome do produto que é mostrado, de acordo com a info vinda pela URL */
  useEffect(() => {
    const productName = Object.keys(products).map(() => {
      return (
        new URL(window.location.href).searchParams.get(
          "igti_checkout_products"
        ) ?? ""
      );
    });

    if (productName[0]?.length > 0) {
      setIsLoading(true);
    }

    setUrlProductName(productName[0]);
  }, [products, setUrlProductName]);

  /**Efeito que atualiza o valor total da compra ao adquirir novos produtos */
  useEffect(() => {
    setTotalValue(
      acquiredProducts.reduce((prev, { valor }) => (prev += valor), 0)
    );
    setIsLoading(false);
  }, [acquiredProducts]);

  /**Efeito que atualiza o valor total da(s) taxa(s) de matrícula ao receber as informações do pagamento */
  useEffect(() => {
    const { value: newEnrollmentValue } = paymentInfo.additionals.find(
      ({ name }) => name === "Taxa(s) de matrícula"
    ) ?? { value: 0 };

    setEnrollmentValue(newEnrollmentValue);
  }, [paymentInfo.additionals]);

  /**Efeito que atualiza o valor de desconto de compra antecipada baseado na forma de pagamento de número de parcelas */
  useEffect(() => {
    /**Recupera o valor total dos produtos sendo adquiridos (sem incluir taxa de matrícula) */
    const totalValue = acquiredProducts.reduce(
      (prev, { valor }) => (prev += valor),
      0
    );

    /**Faz a verificação das condições de aplicação do desconto de compra antecipada (forma de pagamento e número de parcelas) */
    const { payment_discount } =
      Object.keys(productTypes).length > 0 && acquiredProducts.length > 0
        ? acquiredProducts[0].payment_rules ??
          productTypes.find(
            ({ crypted_id }) =>
              crypted_id === acquiredProducts[0].crypted_product_type_id
          )!
        : { payment_discount: [] };

    const { discount, discount_exception } = payment_discount.find(
      ({ payment_type }) => payment_type === paymentInfo.payment_type
    ) ?? {
      discount: 0,
      discount_exception: [
        {
          discount: 0,
          installment: 1,
        },
      ],
    };

    //  const earlyBuyDiscountValue =
    //     discount_exception && paymentInfo.installments === discount_exception.installment
    //        ? totalValue * (discount_exception.discount / 100)
    //        : totalValue * (discount / 100);

    const matchingDiscount = discount_exception
      ? discount_exception.find(
          (item) => item.installment === paymentInfo.installments
        )
      : null;

    const earlyBuyDiscountValue = matchingDiscount
      ? totalValue * (matchingDiscount.discount / 100)
      : totalValue * (discount / 100);

    if (paymentInfo.generalist_voucher) {
      setTotalDiscountValue(paymentInfo.couponValue ?? 0);
    } else {
      setTotalDiscountValue(
        earlyBuyDiscountValue + (paymentInfo.couponValue ?? 0)
      );
    }
  }, [acquiredProducts, productTypes, paymentInfo]);

  /**Efeito que verifica a aplicação de desconto por aquisição de múltiplos itens, quando aplicável */
  useEffect(() => {
    const multipleItemsDiscount = acquiredProducts.reduce(
      (prev, { valor, valor_descontado_original }) => {
        prev += valor_descontado_original
          ? valor - valor_descontado_original
          : 0;
        return prev;
      },
      0
    );

    if (multipleItemsDiscount > 0) {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          discounts: [
            ...prev.discounts.filter(({ name }) => name !== "Múltiplos itens"),
            { name: "Múltiplos itens", value: multipleItemsDiscount },
          ],
        };
      });
    } else {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          discounts: prev.discounts.filter(
            ({ name }) => name !== "Múltiplos itens"
          ),
        };
      });
    }

    if (acquiredProducts.length === 0) {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          discounts: prev.discounts.filter(
            ({ name }) => name !== "Matrícula Antecipada"
          ),
        };
      });
    }
  }, [acquiredProducts, setPaymentInfo, setAcquiredProducts]);

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h6" fontWeight={700} sx={{ marginBottom: "1rem" }}>
          Resumo
        </Typography>

        {isLoading ? (
          <CustomLoader />
        ) : (
          <StyledItemsList>
            {acquiredProducts.map(
              ({ crypted_id, nome, nome_exibicao, link_imagem }) => (
                <li key={crypted_id}>
                  <img
                    src={link_imagem ?? "/placeholder-icon.png"}
                    alt="Ícone do produto"
                    width={75}
                  />
                  <Typography color="GrayText" fontWeight={700}>
                    {// verifica se é produto do tipo bootcamp semestral e prioriza o nome que vem da url
                    urlProductName.length > 0 &&
                    nome_exibicao?.toLowerCase().includes("semestral") &&
                    urlProductName?.toLowerCase().includes("semestral") &&
                    urlProductName !== nome_exibicao
                      ? urlProductName
                      : // verifica se é produto não é do tipo bootcamp, e acrescenta o prefixo, priorizando o nome de exibição
                        urlProductName.length > 0 &&
                        !nome_exibicao?.toLowerCase().includes("bootcamp") &&
                        !nome_exibicao?.toLowerCase().includes("assinatura")
                      ? `${nome_exibicao?.length > 0 ? nome_exibicao : nome}`
                      : // caso o produto não seja selecionado pela url, prioriza o nome de exibição
                      nome_exibicao?.length > 0
                      ? nome_exibicao
                      : nome}
                  </Typography>
                </li>
              )
            )}
          </StyledItemsList>
        )}

        <StyledValuesList>
          <li>
            <Typography color="GrayText">Subtotal</Typography>
            <Typography color="GrayText">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(totalValue)}
            </Typography>
          </li>

          {paymentInfo.additionals &&
            paymentInfo.additionals.length > 0 &&
            paymentInfo.additionals.map(
              ({ name, value }, index) =>
                value !== 0 && (
                  <li key={index}>
                    <Typography color="GrayText">{name}</Typography>
                    <Typography color="GrayText">
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(value)}
                    </Typography>
                  </li>
                )
            )}

          {paymentInfo.discounts &&
            paymentInfo.discounts.length > 0 &&
            paymentInfo.discounts.map(
              ({ name, value }, index) =>
                value !== 0 && (
                  <li key={index}>
                    <Typography color="GrayText">{name}</Typography>
                    <Typography color="green">
                      -{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(value)}
                    </Typography>
                  </li>
                )
            )}

          <li style={{ marginTop: "1rem" }}>
            <Typography fontWeight={700}>Total a pagar</Typography>
            <Typography fontWeight={700} fontSize={18}>
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(paymentInfo.value)}
            </Typography>
          </li>
        </StyledValuesList>

        {paymentInfo.payment_type !== "-" && (
          <div>
            <Divider sx={{ margin: "1rem 0" }} />

            <PaymentTypeContainer>
              <Typography color="GrayText" fontWeight={700}>
                Forma de Pagamento
              </Typography>

              <div>
                <div>
                  {paymentInfo.payment_type && paymentInfo.payment_type !== "-"
                    ? paymentInfo.payment_type.charAt(0).toUpperCase() +
                      paymentInfo.payment_type.split("_")[0].slice(1)
                    : "-"}
                </div>

                <div>
                  {paymentInfo.payment_type === "recorrente" ? (
                    paymentInfo.installments > 1 ? (
                      <>
                        1ª de{" "}
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(
                          (totalValue -
                            totalDiscountValue +
                            (paymentInfo.fee || 0)) /
                            paymentInfo.installments +
                            enrollmentValue
                        )}
                        <br />
                        {paymentInfo.installments - 1}x de{" "}
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(
                          (totalValue -
                            totalDiscountValue +
                            (paymentInfo.fee || 0)) /
                            paymentInfo.installments
                        )}
                      </>
                    ) : (
                      <>
                        1x de{" "}
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(
                          (totalValue -
                            totalDiscountValue +
                            enrollmentValue +
                            (paymentInfo.fee || 0)) /
                            paymentInfo.installments
                        )}
                      </>
                    )
                  ) : paymentInfo.payment_type === "boleto_parcelado" ? (
                    <>
                      {paymentInfo.installments}x de{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(
                        (totalValue -
                          totalDiscountValue +
                          enrollmentValue +
                          (paymentInfo.fee || 0)) /
                          paymentInfo.installments
                      )}
                    </>
                  ) : (
                    <>
                      {paymentInfo.installments}x de{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(
                        (totalValue - totalDiscountValue + enrollmentValue) /
                          paymentInfo.installments
                      )}
                    </>
                  )}
                </div>
              </div>
            </PaymentTypeContainer>
          </div>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default Abstract;
