import {
  Dispatch,
  SetStateAction,
  ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

import { AES, enc } from "crypto-js";

import { baseAPI, baseHeaders } from "src/shared/config/axios";

import { useToast } from "src/shared/contexts/Toast";
import { usePayment } from "src/shared/contexts/Payment";

import IProduct, { IProductType } from "src/shared/interfaces/general/product";

import { ProductTypesIds } from "src/shared/utils/enums";

/**Estrutura esperada das props do contexto
 * @param token token de autenticação para as operações do webservice
 * @param productTypes array contendo os tipos de produtos
 * @param setProductTypes setter do productTypes
 * @param products array contendo todos os produtos disponíveis para aquisição
 * @param setProducts setter do products
 * @param acquiredProducts array contendo os produtos sendo adquiridos
 * @param setAcquiredProducts setter do acquiredProducts
 */
interface IProductData {
  token: string | null;
  productTypes: IProductType[];
  setProductTypes: Dispatch<SetStateAction<IProductType[]>>;
  products: { type: Omit<IProduct, "tipo">[] };
  setProducts: Dispatch<SetStateAction<{ type: Omit<IProduct, "tipo">[] }>>;
  acquiredProducts: IProduct[];
  setAcquiredProducts: Dispatch<SetStateAction<IProduct[]>>;
}

/**Estrutura esperada do provider do contexto */
interface IProductProviderProps {
  children: ReactNode;
}

export const ProductContext = createContext({} as IProductData);

export function ProductProvider({ children }: IProductProviderProps) {
  const { addToast } = useToast();

  /**Informações relativas ao pagamento */
  const { setPaymentInfo } = usePayment();

  /**Variável que mantém o token de autenticação do usuário nas operações */
  const [token, setToken] = useState(null);

  /**Variável que mantém a lista de tipos de produtos disponíveis */
  const [productTypes, setProductTypes] = useState<IProductType[]>([]);
  /**Variável que mantém a relação dos produtos disponíveis com seus respectivos tipos */
  const [products, setProducts] = useState<{ type: Omit<IProduct, "tipo">[] }>(
    {} as { type: Omit<IProduct, "tipo">[] }
  );
  /**Variável que mantém a lista de produtos sendo adquiridos pelo usuário */
  const [acquiredProducts, setAcquiredProducts] = useState<IProduct[]>([]);

  /**Efeito que recupera o token que o usuário irá utilizar na autenticação das operações do checkout */
  useEffect(() => {
    const getToken = async () => {
      const {
        data: { response: token },
      } = await baseAPI.post(
        "/login/",
        {},
        {
          auth: {
            username: process.env
              .REACT_APP_CHECKOUT_HTTPBASIC_USERNAME as string,
            password: process.env
              .REACT_APP_CHECKOUT_HTTPBASIC_PASSWORD as string,
          },
        }
      );

      setToken(token);
      /**Registra o token em sessionStorage */
      sessionStorage.setItem("@checkout_token@", token);
    };

    getToken();
  }, []);

  /**Efeito que recupera os tipos de produto, para armazenamento no contexto */
  useEffect(() => {
    const getProductTypes = async () => {
      const {
        data: { response },
      } = await baseAPI.get(`/product_types/?checkout=True`, {
        headers: baseHeaders(),
      });

      setProductTypes(response);
    };

    token && getProductTypes();
  }, [token, setProductTypes]);

  /**Efeito que recupera os produtos disponíveis e relaciona-os com seus respectivos tipos */
  useEffect(() => {
    const getProducts = async () => {
      const {
        data: { response },
      } = await baseAPI.get(`/products/?checkout=True`, {
        headers: baseHeaders(),
      });

      setProducts(
        (response as IProduct[]).reduce((obj, { tipo, ...item }) => {
          item.crypted_product_type_id === ProductTypesIds.MBA ||
          item.crypted_product_type_id === ProductTypesIds.MBA_ASSINCRONO ||
          item.crypted_product_type_id === ProductTypesIds.MBA_IA
            ? (obj["Pós-Graduação"] = obj["Pós-Graduação"] ?? [])
            : item.crypted_product_type_id === ProductTypesIds.ASSINATURA
            ? (obj["Bootcamp Pass"] = obj["Bootcamp Pass"] ?? [])
            : (obj[tipo] = obj[tipo] ?? []);

          item.crypted_product_type_id === ProductTypesIds.MBA ||
          item.crypted_product_type_id === ProductTypesIds.MBA_ASSINCRONO ||
          item.crypted_product_type_id === ProductTypesIds.MBA_IA
            ? Object.assign(obj["Pós-Graduação"], [
                ...obj["Pós-Graduação"],
                { ...item },
              ])
            : item.crypted_product_type_id === ProductTypesIds.ASSINATURA
            ? Object.assign(obj["Bootcamp Pass"], [
                ...obj["Bootcamp Pass"],
                { ...item },
              ])
            : Object.assign(obj[tipo], [...obj[tipo], { ...item }]);

          return obj;
        }, {} as any)
      );
    };

    token && getProducts();
  }, [token]);

  const applyDiscount = (response: IProduct, earlyDiscountValue: number) => {
    response = {
      ...response,
      valor_descontado:
        response.valor *
        ((100 -
          productTypes.filter(
            ({ crypted_id }) => crypted_id === response.crypted_product_type_id
          )[0].early_discount) /
          100),
    };

    earlyDiscountValue +=
      response.valor *
      (productTypes.filter(
        ({ crypted_id }) => crypted_id === response.crypted_product_type_id
      )[0].early_discount /
        100);

    return { response, earlyDiscountValue };
  };

  /**Efeito que recupera os produtos sendo adquiridos pelo usuário, passados via url */
  useEffect(() => {
    const getAcquiredProducts = async () => {
      const acquiredProductsNames: string[] =
        new URL(window.location.href).searchParams
          .get("igti_checkout_products")
          ?.split(",") || [];
      /**Variável mantendo o valor total descontado devido à matrícula antecipada */
      let earlyDiscountValue = 0;
      let products: IProduct[] = [];
      for (const name of acquiredProductsNames) {
        try {
          let {
            data: { response },
          } = await baseAPI.get<{ response: IProduct }>(
            `/products/search/?name=${name}`,
            {
              headers: baseHeaders(),
            }
          );

          /**Aplica os descontos aplicáveis relativos à(s) matrícula(s) antecipada(s) */
          const product_with_discount = applyDiscount(
            response,
            earlyDiscountValue
          );
          earlyDiscountValue = product_with_discount.earlyDiscountValue;

          products = [...products, product_with_discount.response];
        } catch (e) {
          addToast({
            type: "error",
            title: "Produto(s) inexistente(s)",
            description:
              "O(s) produto(s) informado(s) não existe(m) na base de dados",
          });
        }
      }

      setAcquiredProducts(
        products.map((prod) => {
          /**Verifica se foi passada uma ID da transação na URL (ou se o produto é cobrança avulsa) e, nesse caso, atualiza o valor de todos os produtos com o valor passado como valor da transação */
          const { transaction_id, transaction_value } = JSON.parse(
            new URL(window.location.href).searchParams.get(
              "igti_checkout_payment_info"
            )
              ? AES.decrypt(
                  window.location.href.split("igti_checkout_payment_info=")[1],
                  process.env.REACT_APP_CHECKOUT_URL_SECRET_KEY!
                ).toString(enc.Utf8)
              : '{"transaction_id": null, "transaction_value": 0}'
          );

          return transaction_id ||
            prod.crypted_id === "c4d46d017dfd2a8e40b698e2a2912111"
            ? {
                ...prod,
                valor: transaction_value,
                valor_descontado: transaction_value,
                valor_descontado_original: transaction_value,
              }
            : { ...prod };
        })
      );

      setPaymentInfo((prev) => {
        return {
          ...prev,
          discounts: [
            ...(prev.discounts
              ? [
                  ...prev.discounts.filter(
                    ({ name }) => name !== "Matrícula Antecipada"
                  ),
                ]
              : []),
            {
              name: "Matrícula Antecipada",
              value: earlyDiscountValue,
            },
          ],
        };
      });
    };

    token && Object.keys(productTypes).length > 0 && getAcquiredProducts();
    // eslint-disable-next-line
  }, [token, setAcquiredProducts, setPaymentInfo, productTypes]);

  return (
    <ProductContext.Provider
      value={{
        token,
        productTypes,
        setProductTypes,
        products,
        setProducts,
        acquiredProducts,
        setAcquiredProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

/**Hook utilizado para acessar as informações do contexto */
export function useProduct(): IProductData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("useProduct must be used within a ProductProvider");
  }

  return context;
}
