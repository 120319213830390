import { CardContent } from '@mui/material';
import { FC } from 'react';
import { CompaniesAccordionDiv, StyledCard } from './style';
import { useProduct } from 'src/shared/contexts/Product';

const Companies: FC = () => {
   /**Variáveis que mantém o(s) objeto(s) do(s) produto(s) sendo adquirido(s) pelo usuário */
   const { acquiredProducts } = useProduct();

   return acquiredProducts.length > 0 && acquiredProducts[0].student_companies.length > 0 ? (
      <StyledCard>
         <CardContent>
            <CompaniesAccordionDiv>
               <p>Alunos dessa e das últimas turmas trabalham nas seguintes empresas:</p>
               <div>
                  {acquiredProducts[0].student_companies.map(({ image_link }, index) => (
                     <img src={image_link} alt="Icone empresa" key={index}></img>
                  ))}
               </div>
            </CompaniesAccordionDiv>
         </CardContent>
      </StyledCard>
   ) : (
      <></>
   );
};

export default Companies;
