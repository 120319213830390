import styled from 'styled-components';

import IItem from 'src/shared/interfaces/inputs/item';

/**Container da lista de inputs */
export const InputList = styled.ul`
   width: 100%;

   margin: 0;
   padding: 0;

   list-style: none;
`;

/**Container do item da lista de inputs */
export const InputItem = styled.li<IItem>`
   width: 100%;

   margin: 1rem 0;
   padding: 0;

   display: grid;
   grid-template-columns: ${({ sizes }) => {
      let str = '';

      sizes.forEach((size) => (str += ` ${size}fr`));

      return str;
   }};

   & div {
      margin: 0 0.25rem;
   }
`;
