import { FC } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import ILoader from 'src/shared/interfaces/general/loader';

import { Container } from './loader.style';

/**Componente genérico para utilização de um loader em operações e carregamentos */
const Loader: FC<ILoader> = ({ color = 'var(--color-primary-darker)', size = 30 }) => {
   return (
      <Container>
         <CircularProgress size={size} sx={{ color }} />
      </Container>
   );
};

export default Loader;
