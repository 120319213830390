import styled from 'styled-components';

/**Container geral */
export const Container = styled.header`
   padding: 1rem;
   margin-bottom: 1rem;

   display: grid;
   grid-template-columns: 0.1fr auto;

   background: white;

   box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

   & svg {
      padding: 0.35rem;
   }

   @media (max-width: 600px) {

      padding: 0.8rem;

      overflow-x: auto;

      grid-template-columns: 1fr;

      & * {
         font-size: 0.8rem !important;
      }

      & img {
         margin-bottom: 1.5rem;
      }
   }
`;
