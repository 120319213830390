import { ReactNode, createContext, useContext, useState } from "react";

import Toast from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import IToast from "src/shared/interfaces/general/toast";

interface ToastContextData {
  addToast(message: IToast): void;
}

interface ToastProviderProps {
  children: ReactNode;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export function ToastProvider({ children }: ToastProviderProps) {
  const [toast, setToast] = useState<IToast>({} as IToast);

  /**Função que adiciona o toast à tela */
  const addToast = ({
    type,
    title,
    description,
    time = 7000,
    show = true,
  }: IToast) => {
    setToast({ type, title, description, time, show });
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <Toast
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={toast.time}
        open={toast.show}
        onClose={() =>
          setToast((prev) => {
            return { ...prev, show: false };
          })
        }
      >
        <Alert
          variant="filled"
          severity={toast.type}
          onClose={() =>
            setToast((prev) => {
              return { ...prev, show: false };
            })
          }
        >
          <AlertTitle>{toast.title}</AlertTitle>
          {toast.description}
        </Alert>
      </Toast>
    </ToastContext.Provider>
  );
}

/** Evita repeticao nos arquivos, pois para usar as funcoes desse
 * contexto nao será nessário declarar "useContext ... etc"
 */
export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
}
