import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';

/**Container do accordion */
export const Container = styled.div`
   margin-bottom: 1rem;

   .MuiTabs-flexContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media (min-width: 830px) and (max-width: 1023px) {
         display: flex;

         .MuiButtonBase-root {
            min-width: 50%;
         }
      }
   }
`;

/**Container dos elementos disponíveis para seleção (cursos e assinaturas) */
export const StyledFormControlLabel = styled(FormControlLabel)<{ checked: boolean }>`
   height: 3.5rem;

   margin: 1rem 0 0 0 !important;

   border: 1px solid var(--color-secondary);
   border-radius: 0.25rem;
   border-color: ${({ checked }) => checked && 'var(--color-primary)'};

   & span {
      color: ${({ checked }) => checked && 'var(--color-primary)'};
   }
`;

/**Div exibição de empresas do produto selecionado */
export const CompaniesDiv = styled.div`
   p {
      margin-top: 16px;
      margin-left: 3px;
   }

   div {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-around;
      max-width: 100vw;
      flex-wrap: wrap;

      img {
         height: 28px;
         padding: 0 15px;
         margin-top: 24px;

         @media (max-width: 960px) {
            height: 16px;
            margin-top: 18px;
            padding: 0 8px;
            /* max-width: 120px; */
         }
      }
   }
`;
