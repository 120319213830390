import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';

import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IAccordionEntry } from 'src/shared/interfaces/accordions';

import { StyledAccordion } from './style';

/**Componente genérico que os accordions específicos implementam
 * @param id identificador do accordion no sistema
 * @param title título do accordion
 * @param disabled flag que indica se o accordion deve estar desabilitado
 * @param expanded flag que indica se o accordion deve estar aberto expandido
 * @param setAccordions setter do array de accordions (para controlar a expansão)
 */
const CustomAccordion: FC<
   PropsWithChildren<{
      id: number;
      title: string;
      disabled: boolean;
      expanded: boolean;
      setAccordions: Dispatch<SetStateAction<IAccordionEntry[]>>;
   }>
> = ({ id, title, disabled, expanded, setAccordions, children }) => {
   return (
      <StyledAccordion
         disabled={disabled}
         expanded={expanded}
         onChange={() =>
            setAccordions((prev) =>
               prev.map((acc) =>
                  acc.id === id
                     ? {
                          ...acc,
                          expanded: !acc.expanded,
                       }
                     : { ...acc }
               )
            )
         }
      >
         <AccordionSummary
            id={`panel-${title}-header`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${title}-content`}
         >
            <Typography variant="h6">{title}</Typography>
         </AccordionSummary>

         <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>
   );
};

export default CustomAccordion;
