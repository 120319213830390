import styled from 'styled-components';

import Card from '@mui/material/Card';

/**Container geral */
export const StyledCard = styled(Card)`
   margin: 0.5rem;

   overflow-y: auto;

   ::-webkit-scrollbar {
      width: 0.5rem;
   }

   ::-webkit-scrollbar-track {
      background: transparent;
   }

   ::-webkit-scrollbar-thumb {
      border-radius: 2rem;

      background: var(--color-secondary-dark);
   }

   & .header {
      margin-bottom: 1rem;

      display: flex;
      justify-content: space-between;
   }
`;

/**Lista de itens adquiridos */
export const StyledItemsList = styled.ul`
   & li {
      display: flex;
      align-items: center;

      & img {
         margin-right: 1rem;
      }
   }
`;

/**Lista de valores, descontos e adicionais */
export const StyledValuesList = styled.ul`
   & li {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
   }
`;

/**Container das informações da forma de pagamento */
export const PaymentTypeContainer = styled.div`
   & div {
      margin-top: 1rem;

      display: flex;
      justify-content: space-between;
   }
`;
