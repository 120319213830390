import styled from 'styled-components';

/**Estilização do container do loader */
export const Container = styled.div`
   width: 100%;

   display: flex;
   justify-content: center;
   align-items: center;
`;
