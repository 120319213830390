import styled from 'styled-components';

import Modal from '@mui/material/Modal';

/**Estilização do modal */
export const StyledModal = styled(Modal)`
   width: 400;

   display: flex;
   justify-content: center;
   align-items: center;
`;

/**Conteúdo do modal */
export const Content = styled.div`
   width: 80%;
   height: 80%;

   display: flex;
   flex-direction: column;
   align-items: end;

   & button {
      border: none;

      background: none;

      & svg {
         cursor: pointer;
      }
   }

   & iframe {
      width: 100%;
      height: 100%;

      border: none;
   }
`;
