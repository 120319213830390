import { useState } from "react";

import Typography from "@mui/material/Typography";

import { useProduct } from "./shared/contexts/Product";

import { IAccordionEntry } from "./shared/interfaces/accordions";

import CustomLoader from "src/shared/components/general/Loader";
import CustomHeader from "src/shared/components/Header";

import PersonInfoAccordion from "src/shared/components/accordions/PersonInfoAccordion";
import AddressInfoAccordion from "src/shared/components/accordions/AddressInfoAccordion";
import ItemInfoAccordion from "src/shared/components/accordions/ItemInfoAccordion";
import TermsInfoAccordion from "src/shared/components/accordions/TermsInfoAccordion";
import PaymentInfoAccordion from "src/shared/components/accordions/PaymentInfoAccordion";
import CompletedAccordion from "src/shared/components/general/Accordion/completed";

import Abstract from "./shared/components/Abstract";
import Coupon from "./shared/components/Coupon";

import { StyledBody, StyledFooter, StyledList } from "./App.style";
import Companies from "./shared/components/Companies";

function App() {
  /**Recupera o token de autenticação utilizado nas operações do webservice */
  const { token } = useProduct();

  /**Variável que mantém o passo em que o usuário se encontra, para controle no cabeçalho */
  const [activeStep, setActiveStep] = useState(0);

  /**Array mantendo a relação dos accordions  */
  const [accordions, setAccordions] = useState<IAccordionEntry[]>([
    {
      id: 1,
      group: 1,
      Component: PersonInfoAccordion,
      disabled: false,
      expanded: true,
      completed: false,
      completedInfo: {
        title: "Dados Pessoais",
        button: "Alterar",
        content: [],
      },
    },
    {
      id: 2,
      group: 1,
      Component: AddressInfoAccordion,
      disabled: true,
      expanded: false,
      completed: false,
      completedInfo: {
        title: "Endereço",
        button: "Alterar",
        content: [],
      },
    },
    {
      id: 3,
      group: 2,
      Component: ItemInfoAccordion,
      disabled: true,
      expanded: false,
      completed: false,
      completedInfo: {
        title: "Seleção de Produto",
        button: "Alterar",
        content: [],
      },
    },
    {
      id: 4,
      group: 2,
      Component: TermsInfoAccordion,
      disabled: true,
      expanded: false,
      completed: false,
      completedInfo: {
        title: "Termos e Condições",
        button: "Visualizar",
        content: [],
      },
    },
    {
      id: 5,
      group: 2,
      Component: PaymentInfoAccordion,
      disabled: true,
      expanded: false,
      completed: false,
      completedInfo: {
        title: "Endereço",
        button: "Alterar",
        content: [],
      },
    },
  ]);

  return (
    <div className="App">
      <CustomHeader activeStep={activeStep} />

      {!token || token === "" ? (
        <CustomLoader />
      ) : (
        <div>
          <StyledBody>
            <StyledList>
              {[1, 2, 3].map((val) =>
                val === 3 ? (
                  <div key={val}>
                    <Abstract /> <Coupon accordions={accordions} />{" "}
                    <Companies />
                  </div>
                ) : (
                  <ul key={val}>
                    {accordions
                      .filter(({ group }) => val === group)
                      .map(
                        ({
                          id,
                          Component,
                          disabled,
                          expanded,
                          completed,
                          completedInfo: {
                            title,
                            button,
                            content,
                            extraContent,
                          },
                        }) => {
                          return (
                            <li key={id}>
                              {completed ? (
                                <CompletedAccordion
                                  id={id}
                                  title={title}
                                  button={button}
                                  content={content}
                                  extraContent={extraContent}
                                  setAccordions={setAccordions}
                                  setActiveStep={setActiveStep}
                                />
                              ) : (
                                <Component
                                  id={id}
                                  disabled={disabled}
                                  expanded={expanded}
                                  accordions={accordions}
                                  setAccordions={setAccordions}
                                  setActiveStep={setActiveStep}
                                />
                              )}
                            </li>
                          );
                        }
                      )}
                  </ul>
                )
              )}
            </StyledList>
          </StyledBody>

          <StyledFooter>
            <span>
              <img
                src="logo-color-black.svg"
                alt="Logo"
                width={100}
                style={{
                  filter:
                    "invert(91%) sepia(4%) saturate(0%) hue-rotate(47deg) brightness(77%) contrast(81%)",
                }}
              />

              <Typography variant="body2">
                Copyright 2024 © Faculdade XP
              </Typography>
              <Typography variant="body2">
                CNPJ/MF nº 08.028.776/0001-21
              </Typography>
              <Typography variant="body2">
                Rua Roma, 561 - Santa Lúcia - Belo Horizonte / MG
              </Typography>
            </span>
          </StyledFooter>
        </div>
      )}
    </div>
  );
}

export default App;
