import styled from 'styled-components';

import Card from '@mui/material/Card';

/**Container geral */
export const StyledCard = styled(Card)`
   margin: 0.5rem;

   overflow-y: auto;

   ::-webkit-scrollbar {
      width: 0.5rem;
   }

   ::-webkit-scrollbar-track {
      background: transparent;
   }

   ::-webkit-scrollbar-thumb {
      border-radius: 2rem;

      background: var(--color-secondary-dark);
   }

   & .header {
      margin-bottom: 1rem;

      display: flex;
      justify-content: space-between;
   }
`;

/**Div exibição de empresas do produto selecionado */
export const CompaniesAccordionDiv = styled.div`
   p {
      font-size: 16px;
      font-weight: 700;

      @media (max-width: 960px) {
         font-size: 9.6px;
      }
   }

   div {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      max-width: 100vw;
      flex-wrap: wrap;

      @media (max-width: 960px) {
         justify-content: flex-start;
      }

      @media (min-width: 961px) and (max-width: 1500px) {
         justify-content: space-around;
      }

      img {
         height: 22px;
         margin-top: 15px;

         margin-right: 16px;
         /* margin-left: 8px; */

         @media (max-width: 960px) {
            height: 18px;
         }
      }

      /* img:not(:first-child) {
         margin-left: 20px;
      } */
   }
`;
